import React, {ReactElement} from 'react';
import Landing from './Landing';
import ImageAndText from './ImageAndText';

import GamblingPageImage from 'content-images/2.svg';

const Body = (): ReactElement => <>
    <p>Vanilla payment systems might not work properly for this industry.</p>
    <p>You connect OfelosPay to your platform. Your users will be able to use
        popular payment methods (Visa/MasterCard, QIWI, YooMoney, PayPal, WeChat, Crypto, GCash, Revolut, etc.)
        without restrictions.</p>
    <p>When users want to make a deposit the system provides them with the transfer instructions.</p>
    <p>Once the transfer is made, user waits for a confirmation of the transfer from the recipient or confirms it by
        providing the payment document. The money is credited to the user&apos;s account with your service.</p>
    <p>When users want to withdraw money they create a withdrawal request. As soon as it is
        matched with a deposit request, the money hits the user&apos;s e-wallet or a bank card.</p>
</>;

const Image = (): ReactElement => <img src={GamblingPageImage} alt="OfelosPay for gambling"/>;

const GamblingPage = (): ReactElement => <Landing>
    <ImageAndText
        MainTitle="OfelosPay for iGaming"
        SubTitle="Your are in the iGaming business (online casino, betting, lottery, lootboxes, fantasy sports, etc.).
        Your users open accounts with your service, make deposits and withdraw money."
        Image={<Image/>}
        Body={<Body/>}
    />
</Landing>;

export default GamblingPage;
