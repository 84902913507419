import React, {ReactElement} from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';

import MainPage from './pages/Landing/MainPage';
import MarketplacesPage from './pages/Landing/MarketplacesPage';
import PaymentAggregatorsPage from './pages/Landing/PaymentAggregatorsPage';
import GamblingPage from './pages/Landing/GamblingPage';
import TermsAndConditions from './pages/Landing/TermsAndConditions';
import PrivacyPolicy from './pages/Landing/PrivacyPolicy';

process.env.NODE_ENV !== 'production' &&
console.log(`BRANCH = ${process.env.REACT_APP_BRANCH && `Branch name = ${process.env.REACT_APP_BRANCH}`}}`);

const App = (): ReactElement => <div className="App App-landing">
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<MainPage/>}/>
            <Route path="/marketplaces" element={<MarketplacesPage/>}/>
            <Route path="/payment-aggregators" element={<PaymentAggregatorsPage/>}/>
            <Route path="/igaming" element={<GamblingPage/>}/>
            <Route path="/terms-and-conditions" element={<TermsAndConditions/>}/>
            <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
        </Routes>
    </BrowserRouter>
</div>;
export default App;
