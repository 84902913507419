import React, {ReactElement} from 'react';
import {Link} from 'react-router-dom';

export const scrollToTop = (): void => {
    window.scrollTo(0, 0);
};

const Footer = (): ReactElement => <footer className="ofelos-pay-footer">
    <div className="ofelos-pay-inner">
        <div className="ofelos-pay-copyright">
            <div className="ofelos-pay-privacy-policy">
                <Link to="/terms-and-conditions" onClick={scrollToTop}>Terms & Conditions</Link>
                <Link to="/privacy-policy" onClick={scrollToTop}>Privacy Policy</Link>
            </div>
            <span>Ofelospay © 2022</span>
        </div>
    </div>
</footer>;

export default Footer;
