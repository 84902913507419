import React, {ReactElement} from 'react';
import {Element} from 'react-scroll';

import PersonIconSvg from 'svg/principle-work/person-icon.svg';

const SectionFifth = (): ReactElement => <>
    <section className="ofelos-pay-section fifth">
        <Element className="anchor-section" name="how-to-work"/>
        <div className="ofelos-pay-section-title">
            <h2>How our solution works</h2>
        </div>
        <div className="ofelos-pay-list-items principle-of-work">
            <div className="item">
                <span className="number">1</span>
                <div className="description">
                    <p>OfelosPay is built on direct transactions between end users.</p>
                    <p>Our platform connects all end users of our partners in a single pool.</p>
                </div>
            </div>
            <div className="item">
                <span className="number">2</span>
                <div className="description">
                    <p>Then we link the two users:</p>
                </div>
                <ul className="ofelos-pay-ul-text-icons">
                    <li>
                        <span className="icon">
                            <img src={PersonIconSvg}/>
                        </span>
                        <span className="title">Makes a deposit or a payment</span>
                    </li>
                    <li>
                        <span className="icon">
                            <img src={PersonIconSvg}/>
                        </span>
                        <span className="title">Withdraws money or requests a payout</span>
                    </li>
                </ul>
            </div>
            <div className="item">
                <span className="number">3</span>
                <div className="description">
                    <p>OfelosPay matches requests and provides details for payment. Users exchange funds.</p>
                </div>
                <ul className="ofelos-pay-ul-text-icons">
                    <li>
                        <span className="icon">
                            <img src={PersonIconSvg}/>
                        </span>
                        <span className="title">Receives a deposit</span>
                    </li>
                    <li>
                        <span className="icon">
                            <img src={PersonIconSvg}/>
                        </span>
                        <span className="title">Receives payment</span>
                    </li>
                </ul>
            </div>
        </div>
    </section>
</>;

export default SectionFifth;
