import React, {ReactElement, useEffect} from 'react';
import Landing from './Landing';

import SectionFirst from './SectionFirst';
import SectionSecond from './SectionSecond';
import SectionThird from './SectionThird';
import SectionFourth from './SectionFourth';
import SectionFifth from './SectionFifth';
import SectionSixth from './SectionSixth';
import {scroller} from 'react-scroll';


const MainPage = (): ReactElement => {
    useEffect(() => {
        const anchor = new URLSearchParams(location.search).get('anchor');

        if (anchor) {
            scroller.scrollTo(anchor, {duration: 600, smooth: true});
        }
    }, []);

    return <Landing>
        <SectionFirst/>
        <SectionSecond/>
        <SectionThird/>
        <SectionFourth/>
        <SectionFifth/>
        <SectionSixth/>
    </Landing>;
};

export default MainPage;
