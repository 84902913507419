import React, {ReactElement, ReactNode} from 'react';
import './scss/Landing.scss';

import Header from './Header';
import Footer from './Footer';

interface ILandingProps {
    children: ReactNode
}

const Landing = (props: ILandingProps): ReactElement => <>
    <Header/>
    {props.children}
    <Footer/>
</>;

export default Landing;
