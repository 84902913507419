import React, {ReactElement, useState, useCallback} from 'react';
import {Element} from 'react-scroll';

import TelegramIconSvg from 'svg/messengers/telegram-icon.svg';
import Post from 'frontend_module/src/utils/fetch/Post';
import apiUrl from 'utils/apiUrl';

const SectionSixth = (): ReactElement => {
    const [email, setEmail] = useState('');
    const [errorEmail, setErrorEmail] = useState(false);
    const [textContent, setTextContent] = useState('');
    const [errorText, setErrorText] = useState(false);

    const onChangeEmail = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
        setErrorEmail(false);
    }, []);

    const emailValidation = useCallback((emailValue: string) => {
        const regex = /[a-zA-Z0-9]+[\\.]?(?:[a-zA-Z0-9]+)?[\\@][a-z]{3,9}[\\.][a-z]{2,5}/u;

        const result = !regex.test(emailValue);

        setErrorEmail(result);

        return result;
    }, []);

    const onChangeTextContent = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setTextContent(e.target.value);
        setErrorText(false);
    }, []);

    const textContentValidation = () => {
        const result = !textContent.trim();
        setErrorText(result);
        return result;
    };

    const onSubmit = useCallback((e: React.SyntheticEvent) => {
        e.preventDefault();

        console.log(emailValidation(email), textContentValidation());
        if (emailValidation(email) || textContentValidation()) {
            return;
        }

        new Post({
            params: {feedback: {email, message: textContent}},
            url: apiUrl('feedback')
        })
            .execute()
            .then(response => {
                // Remove alert
                // eslint-disable-next-line no-alert
                alert(response.ok ? 'Message has been sent successfully' : 'Something went wrong');
                setEmail('');
                setTextContent('');
            });
    }, [email, textContent]);

    return <section className="ofelos-pay-section sixth">
        <Element className="anchor-section" name="contact"/>
        <div className="ofelos-pay-section-title">
            <h2>Contact us</h2>
        </div>
        <form className="ofelos-pay-form" onSubmit={onSubmit}>
            <div className="communication">
                <div className="row">
                    <a href="https://t.me/Offelospay" className="link-contact-go telegram">
                        <span className="icon">
                            <img src={TelegramIconSvg}/>
                        </span>
                        <span className="title">ofelospay</span>
                    </a>
                </div>
                <div className="row">
                    <a href="mailto:info@ofelospay.org" className="link-contact-go email">
                        <span className="title">info@ofelospay.org</span>
                    </a>
                </div>
            </div>
            <div className="form-fields">
                <div className={`row${errorEmail ? ' validate-active' : ''}`}>
                    <input
                        id="email"
                        className={`form-input${email && !errorEmail ? ' hasValue' : ''}`}
                        type="text"
                        value={email}
                        placeholder="Your Email *"
                        onChange={onChangeEmail}
                    />
                    <div className="validate-field">
                        <span className="message-field">Please enter valid Email address</span>
                        <span className="round-icon"/>
                    </div>
                </div>
                <div className={`row${errorText ? ' validate-active' : ''}`}>
                    <textarea
                        className={`form-textarea${textContent && !errorText ? ' hasValue' : ''}`}
                        placeholder="Message *"
                        value={textContent}
                        onChange={onChangeTextContent}
                    />
                    <div className="validate-field">
                        <span className="message-field">Please fill Message field</span>
                        <span className="round-icon"/>
                    </div>
                </div>
                <div className="row">
                    <button type="submit" className="button-general">Send</button>
                </div>
            </div>
        </form>
        <span className="star-element"></span>
    </section>;
};

export default SectionSixth;
