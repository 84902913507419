import React, {ReactElement} from 'react';
import {Link} from 'react-router-dom';
import Landing from './Landing';
import ImageAndText from './ImageAndText';

const Body = (): ReactElement => <>
    <p>These Terms of Use govern the terms of your access and use of the Website
        and its content, and constitute your legal agreement with us.</p>
    <p>You should read these Terms carefully before you use the Website.</p>
    <p>BY USING THE WEBSITE YOU AGREE TO THESE TERMS OF USE.</p>
    <p>1. Definitions</p>
    <ol>
        <li>“Terms”, “Terms of Use” means these Ofelospay Terms of Use.</li>
        <li>“We”, “us” means the company that makes the Website available to Users.</li>
        <li>“User”, “you” means a person using the Website.</li>
        <li>“Website” means the website accessible
            at <Link to="/" target="_blank">https://ofelospay.com</Link>,
            including its subdomains, programming code, related technologies, know-hows, databases and design.</li>
        <li>“Content” means texts, design, data, video content or other
            intellectual property, content or information, made available to you by us.</li>
    </ol>
    <p>2. User Restrictions and Obligations</p>
    <p>You agree that you will not do any of the following while using or accessing the Website:</p>
    <ol>
        <li>Impersonate or misrepresent your affiliation with any person or entity or otherwise commit fraud.</li>
        <li>Reproduce, modify, adapt, prepare derivative works based on, perform, display,
            publish, distribute, transmit, broadcast,sell, license or otherwise exploit the Website and  Content.</li>
        <li>Use the Website or Content in a commercial manner.</li>
        <li>Circumvent, disable or otherwise interfere with security related features of the Website, or features
            that prevent or restrict use or copying of any Content.</li>
        <li>Forge any TCP/IP packet header or any part of the header information in any posting or in any way use the
            Website to send altered, deceptive or false source-identifying information.</li>
        <li>Interfere with or disrupt (or attempt to interfere with or disrupt) any web pages available on the Website,
            servers or networks connected to the Website, or the technical delivery systems of our providers or
            disobey any requirements, procedures, policies or regulations of networks connected to the Website.</li>
        <li>Attempt to probe, scan or test the vulnerability of any of our system or network or breach or impair
            or circumvent any security or authentication measures protecting the Website and Content.</li>
        <li>Attempt to decipher, decompile, disassemble or reverse engineer
            any of the software used to provide the Website.</li>
        <li>Attempt to scrape, parse, access, search or meta-search the Website or Content with any engine,
            software, tool, agent, device or mechanism other than software and/or search agents provided by us
            or other generally available third party web browsers.</li>
        <li>Use the Website or Content in any manner not permitted by these Terms.</li>
        <li>Encourage or instruct any other individual to do any of the foregoing or to violate these Terms.</li>
    </ol>
    <p>3. Intellectual Property Rights</p>
    <p>Our Intellectual Property Rights. We own all right, title and interest, including all worldwide intellectual
        property rights in the Website and Content.</p>
    <p>License Grant. Permitted Use. Subject to your compliance with these Terms, we give you a personal, worldwide,
        royalty-free, non-assignable and non-exclusive license to access and use the Website and Content for your own
        personal use. This license is for the sole purpose of enabling you to use and enjoy the benefits of the Website
        in the manner permitted by these Terms.</p>
    <p>Any other use of the Website and Content requires our prior written consent.</p>
    <p>The Website and Content may be used and accessed for lawful purposes only. You agree to abide by all applicable
        local, state, national and foreign laws and regulations in connection with
        your use of the Website and Content.</p>
    <p>4. Your Feedback</p>
    <p>If you choose to communicate to us any feedback, ideas or suggestions for improvement of the Website or Content,
        you grant us free-of-charge, irrevocable, non-exclusive, transferable right to use, modify, share, distribute
        and communicate such feedback for any and all commercial or non-commercial purposes, without charge and free
        of any obligation of attribution. You warrant that any such feedback you communicated to us is not subject to
        any license or any other third party right.</p>
    <p>5. Disclaimer of Warranties</p>
    <p>Your use of the Website and any Content or other information made available through the Website is at your
        sole risk and discretion and we hereby disclaim all liability to you or any third party relating thereto.</p>
    <p>The Website and all Content, materials, information, products and services included therein, are provided
        on an &quot;AS IS&quot; and &quot;AS AVAILABLE&quot; basis without warranties of any kind.
        WE EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, EXPRESS, IMPLIED OR STATUTORY, RELATING TO THE WEBSITE
        AND CONTENT, INCLUDING WITHOUT LIMITATION THE WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR
        PURPOSE, NON-INFRINGEMENT OF PROPRIETARY RIGHTS, COURSE OF DEALING OR COURSE OF PERFORMANCE.</p>
    <p>We disclaim any warranties:</p>
    <ol>
        <li>Regarding the security, accuracy, reliability, timeliness and performance of the Website.</li>
        <li>That the Website or any of Content will be error-free or that any errors will be corrected.</li>
        <li>That the Website or any of Content will be of any particular quality, meet any standards or requirements,
            or conform to any of your expectations in this regard.</li>
    </ol>
    <p>Some states or jurisdictions do not allow the exclusion of certain warranties. Accordingly,
        some of the above exclusions may not apply to you.</p>
    <p>6. Limitation of Liability</p>
    <p>IN NO EVENT WILL WE, OUR SHAREHOLDERS, OFFICERS, DIRECTORS, EMPLOYEES, CONTRACTORS OR AGENTS, BE LIABLE TO
        YOU FOR ANY DAMAGES WHATSOEVER, INCLUDING WITHOUT LIMITATION, DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE
        OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE WEBSITE AND CONTENT WHETHER THE
        DAMAGES ARE FORESEEABLE AND WHETHER OR NOT WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
        THE FOREGOING LIMITATION OF LIABILITY WILL APPLY TO THE FULLEST EXTENT PERMITTED
        BY LAW IN THE APPLICABLE JURISDICTION.</p>
    <p>YOUR SOLE REMEDY FOR DISSATISFACTION WITH THE WEBSITE OR CONTENT IS TO STOP USING THE WEBSITE OR CONTENT.</p>
    <p>YOU ACKNOWLEDGE AND AGREE THAT IF YOU INCUR ANY DAMAGES THAT ARISE OUT OF OUR ACTS OR OMISSIONS,
        THE DAMAGES, IF ANY, ARE NOT IRREPARABLE AND ARE NOT SUFFICIENT TO ENTITLE YOU TO AN INJUNCTION OR
        OTHER EQUITABLE RELIEF RESTRICTING OPERATION OF THE WEBSITE OR CIRCULATION OF CONTENT.</p>
    <p>7. Modifications</p>
    <p>We reserve the right, at our sole discretion:</p>
    <ol>
        <li>To modify or revise these Terms at any time by posting the amended Terms on the Website.
            Please check the most current Terms to ensure that you are aware of all the terms governing
            your use of the Website.</li>
        <li>To make changes, update or discontinue the Website, Content or any format, feature or
            functionality thereof at any time with or without notifying you.</li>
        <li>To terminate or restrict access to the Website or Content for any reason whatsoever.</li>
    </ol>
    <p>Your continued use of the Website after a change or update has been made will constitute your
        acceptance to the revised Terms. If you do not agree with the modifications, please discontinue
        use of the Website immediately.</p>
    <p>These Terms remain effective from the date of acceptance until terminated by you or
        us in accordance with these Terms.</p>
    <p>8. Termination</p>
    <p>You may terminate these Terms at any time by ceasing to use the Website.</p>
    <p>Without limiting other remedies, we may suspend or terminate these Terms with you, or may terminate
        or suspend your use of the Website at any time if:</p>
    <ol>
        <li>You violate any provision of these Terms.</li>
        <li>You infringe proprietary rights, rights of privacy, or intellectual property rights of
            any person, business or organization.</li>
        <li>You engaged in other actions relating to or in the course of using the Website that may be
            illegal or cause liability, harm, embarrassment, harassment, abuse or disruption for you,
            other Users, us, any other third parties or for the Website or Content.</li>
        <li>It is required by applicable law.</li>
        <li>We cease offering the services and/or discontinued the Website.</li>
    </ol>
    <p>Notwithstanding the foregoing, we also reserve the right to terminate the Website
        at any time and for any reason.</p>
    <p>Upon termination of these Terms all licenses and rights to use the Website and Content shall immediately
        terminate; and you will immediately cease any and all use of the Website and Content.</p>
    <p>Any suspension or termination of these Terms will not affect your obligations to us under these Terms,
        including, without limitation, proprietary rights and ownership and limitation of liability, which
        reasonably are intended to survive such suspension or termination.</p>
    <p>9. General</p>
    <p>Notices. Any notices or other communications permitted or required under these
        Terms will be in writing and given by us:</p>
    <ol>
        <li>Via email (to the address that you provide during registration), or</li>
        <li>By posting to the Website.</li>
    </ol>
    <p>Consent to Receive Communications in Electronic Form. For contractual purposes, you:</p>
    <ol>
        <li>Consent to receive communications from us in an electronic form via the
            email address you have submitted; and</li>
        <li>Agree that the Terms and all agreements, notices, disclosures, and other communications
            that we provide to you electronically satisfy any legal requirement that such communications
            would satisfy if it were in writing.</li>
    </ol>
    <p>No Assignment. You will not assign these Terms or assign any rights or delegate any obligations hereunder,
        in whole or in part, whether voluntarily or by operation of law, without our prior written consent.
        Any purported assignment or delegation by you without our appropriate prior written
        consent will be null and void.</p>
    <p>We may assign these Terms or any rights hereunder without your consent.</p>
    <p>No Waiver. The failure by us to exercise, or delay in exercising, a legal right or remedy
        provided by these Terms or by law shall not constitute a waiver of our right or remedy.</p>
    <p>Severability and Integration. If any part of these Terms is held invalid or unenforceable, that
        portion shall be construed in a manner consistent with applicable law to reflect, as nearly as
        possible, the original intentions of the parties, and the remaining portions shall remain
        in full force and effect.</p>
</>;

const TermsAndConditions = (): ReactElement => <Landing>
    <ImageAndText
        MainTitle="Ofelospay Website Terms of Use"
        Body={<Body/>}
    />
</Landing>;

export default TermsAndConditions;
