import React, {ReactElement} from 'react';
import Landing from './Landing';
import ImageAndText from './ImageAndText';

import MarketplacesPageImage from 'content-images/1.svg';

const Body = (): ReactElement => <>
    <p>You add OfelosPay API to your platform. Users want to top up their balance.
        They enter the amount, select OfelosPay payment system and a payment method of their
        choice (Visa/MasterCard, QIWI, YooMoney, PayPal, WeChat, Crypto, GCash, Revolut, etc.)</p>
    <p>The system provides user with the transfer instructions.</p>
    <p>Once the transfer is made, user waits for a confirmation of the transfer
        from the recipient or confirms it by providing the payment document.</p>
    <p>The money is credited to the user’s account with your marketplace.</p>
    <p>The customer makes a purchase on your marketplace or online store.</p>
</>;

const Image = (): ReactElement => <img src={MarketplacesPageImage} alt="OfelosPay for marketplaces"/>;

const MarketplacesPage = (): ReactElement => <Landing>
    <ImageAndText
        MainTitle="OfelosPay for marketplaces"
        SubTitle="Your business is a marketplace or an online store.
        Your customers have accounts or wallets with your service."
        Image={<Image/>}
        Body={<Body/>}
    />
</Landing>;

export default MarketplacesPage;
