import React, {ReactElement, ReactNode} from 'react';

interface IImageAndTextProps {
    MainTitle: string
    SubTitle?: string
    Body: ReactNode
    Image?: ReactNode
}

const ImageAndText = (props: IImageAndTextProps): ReactElement => <section
    className="ofelos-pay-section image-and-text">
    <div className="ofelos-pay-inner">
        <div className="ofelos-pay-section-title">
            <h1>{props.MainTitle}</h1>
        </div>
        <div className="ofelos-content-description">
            {props.Image && <div className="content-image">
                {props.Image}
            </div>}
            <div className="content-text">
                {props.SubTitle && <h2>{props.SubTitle}</h2>}
                <div className="description">
                    {props.Body}
                </div>
            </div>
        </div>
    </div>
</section>;

export default ImageAndText;
