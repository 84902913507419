import React, {ReactElement} from 'react';

import ChatIconSvg from 'svg/buttons/chat-icon.svg';

const SectionFourth = (): ReactElement => <>
    <section className="ofelos-pay-section fourth">
        <div className="ofelos-pay-section-title">
            <h2>Why use our solution</h2>
        </div>
        <ul className="ofelos-pay-ul">
            <li>
                <div className="li-title">
                    <span className="title">Benefits to business</span>
                </div>
                <div className="li-text">
                    <p><b>100%</b> of payments are successful</p>
                    <p>Low fees <b>2-4%</b></p>
                    <p>Payments without restrictions</p>
                </div>
            </li>
            <li>
                <div className="li-title">
                    <span className="title">Benefits for your end users</span>
                </div>
                <div className="li-text">
                    <p>No currency conversion losses</p>
                    <p>Minimal or no fees</p>
                    <p>Privacy protection</p>
                </div>
            </li>
        </ul>
        <div className="ofelos-pay-more">
            <div className="label-support">
                <span className="icon">
                    <img src={ChatIconSvg}/>
                </span>
                <span className="title">Online Support 24/7</span>
            </div>
        </div>
        <span className="star-element"></span>
    </section>
</>;

export default SectionFourth;
