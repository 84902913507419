import React, {ReactElement} from 'react';
import Landing from './Landing';
import ImageAndText from './ImageAndText';

import PaymentAggregatorsPageImage from 'content-images/3.svg';

const Body = (): ReactElement => <>
    <p>Your clients are online businesses that are interested in affordable and convenient
        payment methods with the highest conversion of online payments.</p>
    <p>You add the OfelosPay system to your list of payment methods.</p>
    <p>You connect OfelosPay to your partners via your own methods. Their end users get an
        opportunity to deposit or withdraw money to/from their account with
        your partner&apos;s service using our solution.</p>
</>;

const Image = (): ReactElement => <img src={PaymentAggregatorsPageImage} alt="OfelosPay for payment aggregators"/>;

const PaymentAggregatorsPage = (): ReactElement => <Landing>
    <ImageAndText
        MainTitle="OfelosPay for payment aggregators"
        SubTitle="Your business is a payment aggregator, and you offer
        your clients different payment methods in one solution."
        Image={<Image/>}
        Body={<Body/>}
    />
</Landing>;

export default PaymentAggregatorsPage;
