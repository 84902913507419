import React, {ReactElement} from 'react';
import {Link as LinkScroll} from 'react-scroll';

import VisaLogotypeSvg from 'svg/pay-systems/visa-logotype.svg';
import MasterCardLogotypeSvg from 'svg/pay-systems/mastercard-logotype.svg';
import PayPalLogotypeSvg from 'svg/pay-systems/paypal-logotype.svg';
import RevolutLogotypeSvg from 'svg/pay-systems/revolut-logotype.svg';
import QiwiLogotypeSvg from 'svg/pay-systems/qiwi-logotype.svg';
import WeСhatPayLogotypeSvg from 'svg/pay-systems/wechat-pay-logotype.svg';

const SectionFirst = (): ReactElement => <>
    <section className="ofelos-pay-section first">
        <div className="ofelos-pay-inner">
            <div className="ofelos-pay-about">
                <div className="slogan">
                    <p>Beneficial for your business. Hassle-free for your end users.</p>
                </div>
                <div className="title">
                    <h1>All popular payment<br/> systems in a single solution</h1>
                </div>
            </div>
            <div className="ofelos-pay-list-paysystems">
                <div className="item">
                    <img src={VisaLogotypeSvg} alt="Visa"/>
                </div>
                <div className="item">
                    <img src={MasterCardLogotypeSvg} alt="Mastercard"/>
                </div>
                <div className="item">
                    <img src={PayPalLogotypeSvg} alt="Paypal"/>
                </div>
                <div className="item">
                    <img src={RevolutLogotypeSvg} alt="Revolut"/>
                </div>
                <div className="item">
                    <img src={QiwiLogotypeSvg} alt="Qiwi"/>
                </div>
                <div className="item">
                    <img src={WeСhatPayLogotypeSvg} alt="Wechat"/>
                </div>
                <div className="item">
                    <span className="other">and much more</span>
                </div>
            </div>
            <div className="ofelos-pay-more left">
                <LinkScroll
                    to="how-to-work"
                    className="button-general"
                    smooth={true}
                    duration={700}
                >
                    How it works?
                </LinkScroll>
            </div>
        </div>
        <span className="element-background"></span>
    </section>
</>;

export default SectionFirst;
