import React, {ReactElement} from 'react';
import {Link} from 'react-router-dom';
import {Element} from 'react-scroll';

import MarketPlacesIconSvg from 'svg/solutions/marketplaces-icon.svg';
import AggregatorsIconSvg from 'svg/solutions/aggregators-icon.svg';
import GamesIconSvg from 'svg/solutions/games-icon.svg';

export const scrollToTop = (): void => {
    window.scrollTo(0, 0);
};

const SectionThird = (): ReactElement => <>
    <section className="ofelos-pay-section third">
        <Element className="anchor-section" name="payment-solutions"/>
        <div className="ofelos-pay-banner">
            <div className="ofelos-pay-banner-description">
                <h2>Payment solutions</h2>
                <div className="text-description">
                    <p>Find out what solutions we offer for your business</p>
                </div>
            </div>
            <div className="ofelos-pay-list-items solution">
                <div className="item">
                    <Link to="/marketplaces" onClick={scrollToTop} className="button-solution">
                        <span className="icon">
                            <img src={MarketPlacesIconSvg}/>
                        </span>
                        <span className="title">Marketplaces</span>
                    </Link>
                </div>
                <div className="item">
                    <Link to="/payment-aggregators" onClick={scrollToTop} className="button-solution">
                        <span className="icon">
                            <img src={AggregatorsIconSvg}/>
                        </span>
                        <span className="title">Payment aggregators</span>
                    </Link>
                </div>
                <div className="item">
                    <Link to="/igaming" onClick={scrollToTop} className="button-solution">
                        <span className="icon">
                            <img src={GamesIconSvg}/>
                        </span>
                        <span className="title">iGaming</span>
                    </Link>
                </div>
            </div>
        </div>
    </section>
</>;

export default SectionThird;
