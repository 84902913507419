import React, {ReactElement} from 'react';
import {Link} from 'react-router-dom';
import Landing from './Landing';
import ImageAndText from './ImageAndText';

const Body = (): ReactElement => <>
    <p>This Privacy Policy describes the ways we collect, store, use and manage any
        Personal Data you provide to us or we collect in connection with your use of Ofelospay website
        accessible at <Link to="/"
        target="_blank">https://ofelospay.com</Link> (the &lsquo;Site&rsquo;).</p>
    <p>When we say &lsquo;Personal Data&rsquo; we mean identifiable information about you, like your
        email address or other contact information sent to us via the Site&rsquo;s contact
        form. If you can&rsquo;t be identified (for example, when Personal Data has been aggregated and anonymized),
        then this Policy does not apply.</p>
    <p>BY SENDING US A MESSAGE VIA THE CONTACT FORM AND/OR BY USING THE SITE YOU AGREE TO THIS
        PRIVACY POLICY and the processing of your Personal Data in the manner provided in this Policy
        If you do not agree to the terms of this Privacy Policy, please do not use the Site.</p>
    <p>1. Information We Collect</p>
    <p>When you visit or use our Site we collect and process your Personal Data. The ways we collect
        it can be categorized into the following:</p>
    <ol>
        <li>Information you provide to us directly. When you decide to send us a message via
            the Site&rsquo;s contact form Site you might provide Personal Data to us.</li>
        <li>We do not collect Personal Data using cookies or other tracking technologies.</li>
    </ol>
    <p>2. Legal Bases to Process Personal Data</p>
    <p>Where we collect Personal Data, we will only process it when we have the legal basis for
        the processing set out in applicable data protection laws. Such legal bases are:</p>
    <ol>
        <li>A legal obligation. Various laws and regulations impose certain obligations on us.
            To comply with them we have to process your Personal Data.</li>
        <li>The legitimate interests. Your Personal Data may be processed when we or other third parties have a
            business or commercial reason to process your Personal Data.</li>
        <li>Your consent. In certain limited cases we may process your Personal Data based on
            your consent, for example, when it is required for direct marketing purposes.</li>
        <li></li>
    </ol>
    <p>3. Why We Process Personal Data</p>
    <p>The Information is used for:</p>
    <ol>
        <li>Delivering you the Site and our services,</li>
        <li>Communicating with you,</li>
        <li>Compliance with the applicable law, regulation, legal process or governmental request,</li>
        <li>Disclosure of information to companies in our group of companies following a
            restructure or for internal administrative purposes,</li>
        <li>Enforcement of this Policy and the Terms of Use, and</li>
        <li>Sending you related information, including newsletters, special offers,
            confirmations, updates and security alerts.</li>
    </ol>
    <p>Our processing of your Personal Data may be necessary for us to provide you with the services
        you have requested. If we do not process your Personal Data, we may be unable to provide you
        with all our services, and some functions and features on our Site may not be available to you.</p>
    <p>4. How Long We Retain Information</p>
    <p>You are allowed to correct or delete your Personal Data. You can edit information about yourself
        or delete your account entirely at any time.</p>
    <p>We will keep your Personal Data in your account until it is not closed. Sometimes, we may retain
        your Personal Data for longer periods as permitted or required by law, such as to prevent abuse,
        if required in connection with a legal claim or proceeding, to enforce our agreements or to comply
        with other legal obligations. When we no longer have a legitimate need to process your Personal Data,
        we will delete or anonymize your information from our databases.</p>
    <p>5. Disclosure of Information</p>
    <p>There will be times when we need to share your Personal Data with third
        parties. We will disclose your Personal Data to:</p>
    <ol>
        <li>Other companies in our group of companies,</li>
        <li>Third-party service providers, contractors and partners who assist us in the provision
            of the Site or who help with our business operations,</li>
        <li>Law enforcement agencies, government bodies, courts or other third parties,
            where we think it is necessary to comply with applicable laws or regulations, or to exercise,
            establish or defend our legal rights (where possible and appropriate, we will notify
            you of this type of disclosure),</li>
        <li>An actual or potential buyer (and its agents and advisers) in connection with an actual
            or proposed purchase, merger or acquisition of any part of our business, and/or</li>
        <li>Other persons where we have your consent.</li>
    </ol>
    <p>6. Your Rights</p>
    <p>When the EU General Data Protection Regulation (GDPR) applies to our relations (e.g. you are
        located in the EU), you have the following rights with respect to your Personal Data that we process:</p>
    <ol>
        <li>Right to access. You have the right to access (and obtain a copy of, if required) your Personal Data.</li>
        <li>Right to rectification. You have the right to update your Personal Data or to correct any inaccuracies.</li>
        <li>Right to erasure. You may have the right to request that we delete your Personal
            Data in certain circumstances, such as when it is no longer necessary for the purpose for
            which it was originally collected.</li>
        <li>Right to restriction of processing. You may have the right to request to restrict the use of
            your Personal Data in certain circumstances, such as when you have objected to our use of
            your Personal Data but we need to verify whether we have overriding legitimate grounds to use it.</li>
        <li>Right to data portability. You have the right to transfer your Personal Data to a third party
            in a structured, commonly used and machine-readable format, in circumstances where the Personal
            Data is processed with your consent or by automated means.</li>
        <li>Right to object. You may have the right to object to the use of your Personal Data in certain
            circumstances, such as the use of your Personal Data for direct marketing.</li>
        <li>Right to complain. You have the right to complain to your local data protection authority.
            This right may not be available to you if there is no authority dealing
            with data protection in your country.</li>
    </ol>
    <p>You can exercise your rights at any time by making adjustments in your
        account or by sending an email to <a href="mailto:info@ofelospay.org">info@ofelospay.org</a>.</p>
    <p>7. Security of Personal Data</p>
    <p>We will take commercially reasonable precautions to protect Personal Data from
        loss, misuse and unauthorized access, disclosure, alteration and destruction.</p>
    <p>We process all Personal Data using industry-standard techniques.</p>
    <p>We implement the following administrative, technical, and physical
        measures for the protection of Personal Data:</p>
    <ol>
        <li>Administrative measures. Access to your Personal Data is limited to authorized
            personnel who have a legitimate need to access it based on their job descriptions.
            In case third-party contractors process Personal Data on our behalf, similar requirements are imposed.</li>
        <li>Technical measures. Personal Data is transmitted in encrypted format using SSL technology.
            We use up-to-date firewall protection and high-quality antivirus and anti-malware software.</li>
        <li>Physical measures. We store Personal Data on our own servers, no third party can
            have access to this data.</li>
    </ol>
    <p>8. International Data Transfers</p>
    <p>When we process and share data, it may be transferred to, and processed in, countries other than
        your country. These countries may have laws different to what you are used to. Where Personal
        Data is processed in another country, we put safeguards in place to ensure your
        Personal Data remains protected.</p>
    <p>For individuals in the European Economic Area (EEA), this means that your data may be
        transferred outside of the EEA. Where your Personal Data is transferred outside the EEA,
        it will be transferred to countries where we have compliant transfer mechanisms in place to protect
        your Personal Data, in particular, by implementing the European Commission&rsquo;s Standard Contractual
        Clauses to the contracts with the entities the data is transferred to.</p>
    <p>9. Modifications</p>
    <p>We may modify or revise this Policy at any time by posting the amended
        Policy on the Site. Please check the most current Policy.</p>
    <p>10. Privacy Related Inquiries</p>
    <p>If, for any reason, you are concerned with the way that we may be using your Personal
        Data, you have questions about the privacy aspects of the Site, please, contact
        us at <a href="mailto:info@ofelospay.org">info@ofelospay.org</a>.</p>
</>;

const PrivacyPolicy = (): ReactElement => <Landing>
    <ImageAndText
        MainTitle="Ofelospay Privacy Policy"
        Body={<Body/>}
    />
</Landing>;

export default PrivacyPolicy;
